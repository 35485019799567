import React from "react";
import {FormattedMessage} from "react-intl";

const AboutFa = () => (
    <div className="line-height-2 pl-4 font-md">
        <p>
            وب سایت <FormattedMessage id="remini"/> با ایده سهولت دسترسی به
            کالاهایی که توی
            کشور یا شهرتون نیست ایجاد شده.
        </p>
        <p>
            اگر شما هم بعد از تحریم های زیاد نتونستید یکسری محصولات خاص بهداشتی، آرایشی، سلامت و حتی خوراکی که قبلا
            به کشور وارد می شد رو پیدا کنید؛
        </p>
        <p>
            اگر شکلات سوئیسی و روغن زیتون مدیترانه و زعفرون ایرانی و یا هر چیزی از هر مکانی توی دنیا رو لازم دارید؛
        </p>
        <p>
            و حتی اگر دوست یا یکی از اعضای خانوادتون توی شهر یا کشور دیگه ای زندگی میکنه و میخواید با فرستادن خرما،
            پسته، زعفرون و یا آجیل شب عید خوشحالش کنید؛
        </p>
        <p>
            <FormattedMessage id="remini"/> با کمک افرادی که از سراسر جهان به کشور شما سفر می کنن بسته شما را رایگان به
            دستتون میرسونه.
        </p>
        <p>
            توی صفحه اصلی سایت میتونید درخواست ها رو از سراسر دنیا ببینید و اگر به کشوری سفر می کنید، میتونید با
            درخواست کننده هایی که از کشور یا شهر شما چیزی درخواست کردن ارتباط برقرار کنید و کمک کنید تا بسته مورد
            نظرشون به دستشون برسه.
        </p>
        <p>
            توی صفحه "مرسولات من" میتونید مرسوله دلخواه خودتون رو تعریف کنید و از کاربرانی که از کشور دلخواهتون میان
            بخواید تا اونو به دستتون برسونن.
        </p>
        {/*<p>*/}
        {/*    اگر چیزی که نیاز دارید باید خریداری بشه یا مبلغی لازم هست که پرداخت بشه، میتونید از کیف پول اکانتتون*/}
        {/*    استفاده کنید، <h3 className="d-inline-block font-md">Remini</h3> براتون این بستر رو به وجود آورده تا بتونید مبلغ دلخواهتون رو به کیف پول کاربر دیگه*/}
        {/*    ای انتقال بدید.*/}
        {/*</p>*/}
        <p>
            در سایت <FormattedMessage id="remini"/> خرید به صورت مستقیم
            انجام نمی شه و تراکنش
            مالی امکان پذیر نیست.
        </p>
        {/*<p>*/}
        {/*    در صورت هرگونه پیشنهاد اینجا کلیک کنید.*/}
        {/*</p>*/}

    </div>
);

export default AboutFa;